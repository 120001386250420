<template>
  <div>
    <div v-if="categories" class="category">
      <template v-for="(cat, index) in categories">
        <CategoryCard v-if="cat.image2" :key="index" :scSize="scSize" :item="cat" />
      </template>
    </div>
  </div>
</template>
<script>
import CategoryCard from "@/parts/Front/nicecomponents/subcomponents/CategoryCard";

export default {
  name: "HomeCategories",
  components: { CategoryCard },
  data() {
    return {
      scSize: "desktop",
    };
  },
  mounted() {
    this.setScreen();
    window.addEventListener("resize", this.setScreen);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setScreen);
  },
  methods: {
    setScreen() {
      if (window.innerWidth < 520) {
        this.scSize = "mobile";
      } else if (window.innerWidth < 1000) {
        this.scSize = "tablet";
      } else {
        this.scSize = "desktop";
      }
    },
  },
  computed: {
    categories() {
      if (!this.$store.getters["front/getHomeData"]) {
        return [];
      } else {
        return this.$store.getters["front/getHomeData"].special_categories;
      }
    },
  },
};
</script>
<style scoped>
.category {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 15px;
  }
}
@media (max-width: 1000px) and (min-width: 520px) {
  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
