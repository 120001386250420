<template>
  <div class="havinLogo__container">
    <router-link v-if="homeData" to="/" class=" d-inline-block">
      <img
        loading="lazy"
        :src="homeData.settings.customer_setting?homeData.settings.customer_setting.logo:''"
        class="w-100 h-100"
        alt="hovin"
      />
    </router-link>
  </div>
</template>
<script>
export default {
  computed: {
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>
<style scoped>
.havinLogo__container {
  width: 45px;
}
</style>
