<template>
  <div class="box-select-container">
<!--    :disabled="option.isDisabled"-->

    <button
        class="box-select-option btn"
         :class="isSelected(option.id) ? 'selected' : 'btn-outline-dark' + ' ' + getAdditionalClass(option)"
         :disabled="option.isDisabled"
         @click="select(option.id)"
         :key="option"
         v-for="option in options">
      <span>{{ option.label }}</span>
      <!-- <canvas v-if="option.isDisabled" class="canvas"></canvas> -->
    </button>
  </div>
</template>

<script>
export default {
  name: "BoxSelect",
  props: {
    options: Array, // id, label, idDisabled
    value: [String, Number]
  },
  data() {
    return {
      realValue: this.value
    }
  },
  mounted() {

  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.realValue = this.value;
        this.draw()
      }
    }
  },
  methods: {
    draw() {
      document.getElementsByClassName("canvas").forEach(c => {
        let bounding = c.getBoundingClientRect();
        let width = bounding.width;
        let height = bounding.height
        c.height = height;
        c.width = width
        let ctx = c.getContext("2d");
        ctx.strokeStyle="red";
        ctx.moveTo(0,height);
        ctx.lineTo(width,0);
        ctx.stroke();
        ctx.moveTo(0,0);
        ctx.lineTo(width,height);
        ctx.stroke();
      })
    },
    select(value) {
      this.$emit('input', value)
    },
    isSelected(id) {
      return this.realValue === id;
    },
    getAdditionalClass(option) {
      switch (option.label.length) {
        case 7: return 'seven'
        case 6: return 'six'
        case 5: return 'five'
        case 4: return 'four'
        case 3: return 'three'
        case 2: return 'two'
        case 1: return 'one'
        default: return 'seven'
      }
    }
  }
}
</script>

<style scoped>
  @media screen  and (max-width: 768px){
    .box-select-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 7px;
    }
  }
  .box-select-option span {
    vertical-align: bottom;
    vertical-align: sub;
  }

  .box-select-option {
    min-width: 42px;
    margin-left: 5px;
    position: relative;
    border-radius: 0;
  }

  .box-select-option:disabled:after {
    content: 'ناموجود';
    position: absolute;
    top: 100%;
    left:50%;
    transform:translate(-50%);
    width: max-content;
    color: red;
    font-size: 12px;
  }
  /* .box-select-option:disabled:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -20%;
    width: 137%;
    height: 1.4px;
    background: red;
    transform: rotate(-45deg);
  } */

  .btn-outline-dark.disabled, .btn-outline-dark:disabled{
    color: white;
    background-color: grey;
    opacity: 0.5;
  }
  .selected {
    box-shadow: 0 0 0 0.13rem var(--color-theme)!important;
    font-weight: bold;
  }

  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
</style>
<style>
.box-select-container .btn-outline-dark {
  border-color: var(--color-theme);
}
</style>
