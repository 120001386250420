<template>
  <footer class=" footer-bg d-flex flex-column">
    <div class="main-footer  container mb-md-0 mb-4">
      <section class="d-flex flex-column flex-lg-row justify-content-between">
        <div class=" col-12 col-lg-5 mt-lg-3 mt-5">
          <div class="mt-3">
            <h6
              v-if="homeData"
              class="text-color-444 fontsize-heavy font-weight-light"
            >
              خط پشتیبانی واتساپ :
              {{ homeData.settings.customer_setting?homeData.settings.customer_setting.mobile:"" }}
              <!-- خدمات مشتریان -->
            </h6>
          </div>
        </div>
        <div
        v-if="homeData"
         class=" col-12 col-lg-2">
          <div
            class="d-flex justify-content-between justify-content-lg-center"
            v-html="homeData.settings.customer_setting.enamad"
          ></div>
        </div>

        <section class="get-subscribe mt-4 col-lg-4 col-12">
          <div
            class="d-flex  justify-content-between align-items-center flex-wrap"
          >
            <SocialNetworks />
          </div>
        </section>
      </section>
    </div>
    <hr />
    <section
      class="d-flex  mt-2 mb-2 container justify-content-center justify-content-center footerCopyright"
    >
      <p class="text-center">
        <svg
          class="d-sm-inline-block d-none ml-1"
          height="20"
          viewBox="0 0 512 512"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#666"
            d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
          />
        </svg>
        تمامی حقوق این سایت مربوط به
        <span v-if="homeData" class="font-weight-bold">سایت {{ homeData.settings.customer_setting?homeData.settings.customer_setting.title:''}}</span> می باشد
      </p>
      <p class="mb-1">
        طراحی شده:
        <a style="color:#ed1f73" target="_blank" href="https://shetabit.com/"
          >گروه شتاب</a
        >
      </p>
    </section>
  </footer>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
import { BSkeleton } from "bootstrap-vue";
import ZWhatsappSvg from "./ZWhatsappSvg";
import SocialNetworks from "./SocialNetworks";

export default {
  name: "Footer",
  components: { SocialNetworks, ZWhatsappSvg, DynamicLinker, BSkeleton },
  computed: {
    // دریافت محتوای درخواست هوم
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      mobile: null,
      email: null,
      disabled: false,
    };
  },
  created() {},
  methods: {
    findUrl,
    sendEmail() {
      if (!this.email) {
        return window.swal({
          title: "خطا!",
          text: "لطفا ایمیل خود را وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      } else if (!this.validEmail(this.email)) {
        return window.swal({
          title: "خطا!",
          text: "لطفا فرمت ایمیل خود را به درستی وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      }
      const fromData = new FormData();
      fromData.append("email", this.email);
      this.disabled = true;
      this.$axios.post("/front/newsletters", fromData).then((response) => {
        this.disabled = false;
        this.email = null;
        window.swal({
          title: "تبریک",
          text: "ایمیل شما برای عضویت در خبرنامه ما ثبت سیستم شد",
          icon: "success",
          timer: 2000,
        });
      });
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendMobile() {
      if (!this.mobile) {
        return window.swal({
          title: "خطا!",
          text: "لطفا شماره تماس خود را وارد کنید",
          icon: "warning",
          timer: 150000,
        });
      }

      const fromData = new FormData();
      fromData.append("mobile", this.mobile);
      if (this.$route.query.source) {
        fromData.append("source", this.$route.query.source);
      }
      this.disabled = true;
      this.$axios
        .post("/front/offers_newsletters", fromData)
        .then((response) => {
          this.disabled = false;
          this.mobile = null;
          window.swal({
            title: "تبریک",
            text: response.data.message,
            icon: "success",
            timer: 150000,
          });
        })
        .catch((err) => {
          this.$root.notify(err);
          this.disabled = false;
        });
    },
  },
};
</script>

<style scoped>
.imgSkeleton {
  height: 70px;
}

.errorEmail {
  border-bottom: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.enamad-container {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.newsletter-btn {
  /* background: var(--color-theme); */
  background: transparent;
}

.get-subscribe form {
  height: 40px;
  border: 2px solid var(--color-da);
  transition: 0.3s;
}

.get-subscribe form:focus-within {
  border: 2px solid var(--color-theme2);
}

/* .get-subscribe form input::placeholder{
    color: white;
} */

.get-subscribe form svg > path {
  transition: 0.3s;
}

.get-subscribe form button {
  height: 38px;
  transition: 0.3s;
  position: relative;
  bottom: -1px;
  left: -3px;
}

.get-subscribe form button:hover {
  box-shadow: 0 0 5px #ccc;
}

.newsletter-title {
  flex-shrink: 0;
  font-size: 19px;
  font-weight: bold;
  margin-left: 10px;
}

@media screen and (max-width: 587px) {
  .newsletter-title {
    font-size: 1rem;
  }
}

p {
  font-size: 14px;
}

.footerCopyright {
  gap: 15px;
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .khabarname {
    flex-wrap: wrap;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
  }
}
.mobile-support {
  display: flex;
  gap: 20px;
  align-items: baseline;
  flex-wrap: wrap;
}
.newsletter-btn svg {
  transform: rotate(180deg);
}
.swal-modal .swal-text {
  text-align: center !important;
}
</style>
<style>
.swal-text {
  text-align: center;
}
</style>
