<template>
  <div id="specifications" class="tab-pane fade pb-3" :class="activeTab === 'specifications' ? 'in active show' :''">
    <div class="">
      <table class="table table-bordered fontsize-small">
        <tbody>
        <tr v-for="specification in product.specifications" :key="specification.id">
          <th scope="row" class="bg-color-fa text-center w-25 "> {{specification.label != null ? specification.label : specification.name }}</th>
          <td class="text-right pr-3 pr-sm-4"
              :class="{'multiline': specification.pivot && specification.pivot.value
                    && specification.pivot.value.length > 100}">
            <template v-if="specification.pivot && specification.pivot.specification_values != ''">
              <span v-for="(values, index) in specification.pivot.specification_values" :key="index"> {{values.value }} {{index != specification.pivot.specification_values.length - 1 ? '-' : ''}} </span>
            </template>
            <template v-else-if="specification.pivot && specification.pivot.specification_value">
              {{ specification.pivot.specification_value.value }}
            </template>
            <template v-else-if="specification.pivot && specification.pivot.value">
              {{ specification.pivot.value }}
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductSpecifications',
  props:['activeTab' , 'product'],
}
</script>
<style scoped>
@media screen and (max-width: 768px) {
  table tr th:first-child {
    text-align: right!important;
  }

  .multiline {
    line-height: 2.1!important;
  }

}

table td {
  white-space: pre-wrap;
}

.multiline {
  line-height: 2.4;
}
</style>
