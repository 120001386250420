<template>
    <div v-if="homeData && homeData.sliders.length" :class="{mobile: mediaQueries.mobile}" class="banner">
      <b-skeleton-img v-if="status"></b-skeleton-img>
      <section v-else class="banner top-slider position-relative">
        <div class="swiper-container h-100">
          <div  class="swiper-wrapper">
            <div
                v-for="slider in sliders"
                :key="slider.id"
                class="swiper-slide bg-color-ea"
            >
              <div v-if="slider.description"
                   class="
                top-slider-content
                d-flex
                justify-content-between
                container
                mx-auto
              "
              >
                <div class="text-right my-auto ml-3">
                <span
                    class="
                    top-header-discount
                    bg-color-themeRed
                    fontsize-mini
                    text-white
                    px-2
                  "
                    v-if="slider.custom_fields && slider.custom_fields.discount"
                >{{ slider.custom_fields.discount }}</span
                >
                  <p class="mb-2 fontsize-xheavy text-color-666" style="font-family: yekan-bakh-bold"
                     v-if="slider.custom_fields && slider.custom_fields.short_description">
                    {{ slider.custom_fields.short_description }}
                  </p>
                  <h5 class="font-weight-bold mb-0 text-black fontsize-fat">
                    {{ slider.title }}
                  </h5>
                  <p
                      class="
                    fontsize-medium
                    text-justify text-color-444
                    line-height2
                    d-lg-block d-none
                  "
                  >
                    {{ slider.description }}
                  </p>
                  <div>
                    <router-link
                        v-if="slider.linkable_type != null"
                        :to="findUrl(slider.linkable_type, slider.linkable_id)"
                    >
                      <button
                          type="button"
                          class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-medium
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                      >
                        اطلاعات بیشتر ...
                      </button>
                    </router-link>

                    <a v-else :href="slider.link">
                      <button
                          type="button"
                          class="
                        top-slider-more
                        d-md-inline-block d-none
                        text-color-666
                        fontsize-mini
                        py-md-2 py-1
                        px-md-3 px-2
                        bg-none
                      "
                      >
                        اطلاعات بیشتر ...
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <DynamicLinker class="top-slider-img" :item="slider">
                <img
                    :src="slider.image.url"
                    class="w-100 h-100"
                    :alt="slider.title"
                />
              </DynamicLinker>
            </div>
          </div>
          <!-- Add Pagination -->
          <!-- <div class="d-md-none swiper-pagination"></div> -->
          <!-- Add Arrows -->
        </div>
        <div class="d-md-block d-none swiper-button-next text-center">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-chevron-compact-right"
              viewBox="0 0 16 16"
          >
            <path
                fill-rule="evenodd"
                d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                fill="#999"
            />
          </svg>
        </div>
        <div class="d-md-block d-none swiper-button-prev">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-chevron-compact-left"
              viewBox="0 0 16 16"
          >
            <path
                fill-rule="evenodd"
                d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                fill="#999"
            />
          </svg>
        </div>
      </section>
    </div>
</template>
<script>
import {BSkeletonImg} from 'bootstrap-vue'
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";

export default {
  inject: ['mediaQueries'],
  components: {
    BSkeletonImg,
    DynamicLinker
  },
  computed: {
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    sliders() {
      let sliders;
      if (this.mediaQueries.medium) {
        sliders = this.homeData.sliders.filter(s => s.group.includes('mobile'));
      } else {
        sliders = this.homeData.sliders.filter(s => !s.group.includes('mobile'));
      }
      sliders.forEach(slider => {
        try {
          slider.custom_fields = this.getSliderCustomFields(slider);
        }catch (e) {}
      })

      return sliders
    },
  },
  methods: {
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy()
          }
        } catch (e) {}

        this.slider = new Swiper(".top-slider .swiper-container", {
          spaceBetween: 0,
          //   centeredSlides: true,
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".top-slider .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".top-slider .swiper-button-next",
            prevEl: ".top-slider .swiper-button-prev",
          },
        });
      });
      this.$nextTick(() => {
        this.onResize()
      })
    },

  }
}
</script>
<style scoped>
.banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* height: 52vw; */
  height: auto;
}

@media screen and (max-width: 768px) {
  .banner {
    height: auto;
  }

  .top-slider-img {
    position: unset;
  }
}

.banner img {
  width: 100%;
}

.banner.mobile {
  /* height: 52vw; */
  height: auto;
}

.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}
</style>
