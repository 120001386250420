<template>
  <div>
    <section class="cart-lists">
      <div class="cart-lists-top p-3 d-flex border-bottom justify-content-between">
        <div class="d-flex align-items-center ">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
              fill="#666" />
          </svg>

          <span class="text-color-theme rounded-circle weight-bold fontsize-large mr-1 pt-1"><span
              class="">{{cart != null && cart.cartItems != null ? quantity : 0 }}</span> مورد</span>
        </div>
        <button
          type="button"
          class="cart-lists-btn bg-none"
          @click="cartListsClose()"
        >
          <span class="font-weight-bold">
            <CloseSvg width="24"/>
          </span>
        </button>
      </div>
      <div class="cart-lists-content">
        <ul
          v-if="cart != null && cart.cartItems != null && cart.cartItems != ''"
        >
          <li
            class="cart-lists-item px-2 border-bottom"
            v-for="(cartItem, index) in cart.cartItems"
            :key="index"
          >
            <CartListItem v-model="cart.cartItems[index]" :index="index" />
          </li>
        </ul>
        <div v-else class="text-center mt-5">
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
              fill="#999"
            />
          </svg>
          <h5 class="text-color-999 pt-3 fontsize-heavy">سبد خرید شما خالی می باشد.</h5>
        </div>
      </div>
      <div class="cart-lists-bottom p-3 bg-color-f1">
        <div class="bg-color-eai p-3 mb-2">
          <div class="d-flex justify-content-between fontsize-small  text-color-444 mb-2">
            <div class="font-weight-bold">تخفیف کل</div>
            <div>
              <span v-if="totalSaving != 0" class="font-weight-bold">{{
                totalSaving | price
              }}</span>
              <span v-else class="font-weight-bold">0</span>
           

              <span class="font-weight-bold pr-1">تومان</span>
            </div>
          </div>
          <div class="d-flex justify-content-between text-color-theme ">
            <div class="weight-bold fontsize-large">مجموع </div>
            <div>
              <span v-if="totalPrice != 0" class="weight-bold fontsize-large">{{totalPrice | price}} </span>
              <span v-else class="weight-bold">0 </span>
              <span class="weight-bold">تومان</span>
            </div>
          </div>
        </div>

        <router-link @click.native="continueCartClicked" to="/cart" :disabled="totalPrice == 0 || disabled"
          class="prosecced-checkout bg-color-theme text-white text-center p-2 w-100 fontsize-small weight-bold d-inline-block">مشاهده سبد خرید</router-link>
      </div>
    </section>
    <div class="cart-lists-close-resp" @click="cartListsClose()"></div>
    <!-- close cartLists by click to body-->
  </div>
</template>

<script>
import { cartListsOpen } from "@/libraries/functions";

import CartListItem from "@/parts/Front/components/cart/CartListItem";
import CloseSvg from "@/components/svgs/CloseSvg";
export default {
  name: "CartLists",
  components: {
    CloseSvg,
    CartListItem,
  },
  data() {
    return {
      disabled: false
    };
  },
  watch: {
    "$route.path"() {
      this.cartListsClose();
    },
  },
  computed: {
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    totalSaving() {
      console.log(this.cart);
      return this.cart ? this.cart.getTotalSaving() : 0;
    },
    totalPrice() {
      return this.cart ? this.cart.getTotalPrice() : 0;
    },
    quantity() {
      if (this.cart) {
        return this.cart.cartItems.reduce((sum, item) => {
          sum += item.quantity;
          return sum;
        }, 0);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    if (this.$route.fullPath.includes("#cart-modal")) {
      // this.$router.replace(this.$route.fullPath.replace("#cart-modal", ""));
      cartListsOpen();
    }
    window.removeEventListener("popstate", this.checkBackButton);
    window.addEventListener("popstate", this.checkBackButton);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
  },
  methods: {
    cartListsClose() {
      // عین همین کد توی ی کومپوننت دیگه هست
      let cartLists = document.getElementsByClassName("cart-lists")[0];
      const cartListsCloseResp = document.getElementsByClassName(
        "cart-lists-close-resp"
      )[0];
      cartListsCloseResp.style.display = "none";
      cartLists.style.left = "-500px";
      this.$router.replace(this.$route.fullPath.replace("#cart-modal", "")).catch(e => {});
    },
    checkBackButton() {
      if (!this.$route.fullPath.includes("#cart-modal")) {
        this.cartListsClose();
      }
    },
    continueCartClicked() {
      if (this.$route.path === '/cart') {
        this.cartListsClose()
      }
    }
    // negCounter(){
    //   if (this.counter > 0){
    //     this.counter -- ;
    //   }
    // }
  },
};
</script>

<style scoped>
.cart-lists-content {
  scrollbar-color: var(--color-theme) var(--color-theme2);
  scrollbar-width: thin;
}
.cart-lists .prosecced-checkout {
  font-size: 16px !important;
}
</style>
